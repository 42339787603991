<template>
    <!-- 전체 콘텐츠 영역 -->
    <div class="main-contents">
        <div class="news_wrap">
          <div class="path">뉴스센터 > 하이프로 뉴스</div>
          <div class="title_wrap">
            <h2>하이프로 뉴스</h2>
          </div>
          <!-- list -->
          <div class="news-list">
            <ul>
              <li v-for="(news, index) in newsList" :key="news.newsCenterSeq">
                <a href="javascript:void(0);" @click="goNewsDetail(news.newsCenterSeq)">
                  <span class="numb">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + index+1}}</span>
                  <span class="text"> <span v-if="news.newNotice=='Y'" class="icon-new">NEW</span>{{news.newsCenterTitle}}</span>
                  <span class="date">{{news.newsCenterReg | date('yyyy. MM. dd')}}</span>
                </a>
              </li>
            </ul>
          </div>          
            <!-- 페이징부분 -->
		    <paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
        </div>
    </div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
export default {
    components: { pagingComp },
    data() {
        return {
            input : {
                pageIndex: 1,
            },
            newsList : [],
            pageInfo: {},
        }
    },
    
    mounted() {
        this.getNewsList();
    },

    methods : {
        getNewsList() {
            this.$.httpPost('/api/main/bbs/getNewsList', this.input)
                .then(res => {
                    this.newsList = res.data.newsList;
                    this.pageInfo = res.data.pageInfo;
                }).catch(this.$.httpErrorCommon);
        },

        goNewsDetail(idx) {
            this.$router.push({name: 'BBS003M02', params:{newsCenterSeq:idx}});
        },

        goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getNewsList();
		},
    }
}
</script>